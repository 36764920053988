@use "../cc/variables" as var;

.columns {
  margin: 40px 0;

  .column {
    img {
      padding: 0;
      margin: 0;
    }

    &.blue {
      color: var.$primary !important;

      a {
        color: var.$primary !important;

        &:hover {
          color: adjust_color(var.$primary, $lightness: 30%) !important;
        }
      }
    }

    &.yellow {
      color: var.$primary2 !important;

      a {
        color: var.$secondary !important;

        &:hover {
          color: adjust_color(var.$secondary, $lightness: 30%) !important;
        }
      }
    }

    &.gray {
      color: var.$mud !important;

      a {
        color: var.$mud !important;

        &:hover {
          color: adjust_color(var.$mud, $lightness: 30%) !important;
        }
      }
    }

    h1, h2, h3, h4, h5, h6 {
      padding: 0;
      margin: 0 0 20px 0;
    }

  }
}