@use "../cc/variables" as var;

.wsp-container {
  h2 {
    text-align: center;
    //text-transform: uppercase;
    border-bottom: 1px solid var.$sand;
    padding: 15px;
  }

  ul {
    margin-bottom: 50px;

    li {
      list-style-type: none !important;
    }
  }
}