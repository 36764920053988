@use "../cc/variables" as var;

// no top margin for description place content
.cc-content > .container-lg > :first-child {
  margin-top: 0 !important;
}

// no top margin for first in sidebar
.cc-content > .container-lg .sidebar > :first-child {
  margin-top: 0 !important;
}

// no top margin on first in article
.cc-content__article-inner > :first-child {
  margin-top: 0 !important;
}

.cc-content {
  background-color: white;
  padding-top: 70px;
  padding-bottom: 100px;
  overflow-wrap: break-word;

  &__article {

    :not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(.nav) {
      > a:link,
      > a:active,
      > a:visited {
        padding: 0 4px 4px 4px;
        box-shadow: inset 0 -1.2px 0 adjust_color(var.$link-color, $lightness: 30%);

        &:hover {
          box-shadow: inset 0 -2.2px 0 adjust_color(var.$link-hover-color, $lightness: 30%);
        }
      }
    }

    #platform-editorial-links a {
      padding: 12px;
      box-shadow: none;
    }
  }
}

.qdf-bottom-active .cc-content {
  padding-bottom: 0;
}

