@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

$card_link_z: 5;
$has_link_z: $card_link_z + 1;
$no_link_z: $card_link_z - 1;
$image_over_z: $card_link_z - 2;
$image_z: $card_link_z - 3;
$border-radius: var.$border-radius * 2;
.cccard {
  $this: &;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: minmax(0, auto);
  grid-template-rows:  auto auto 1fr;

  grid-template-areas: 'stamps' 'icon' 'pt-badge-headshot' 'title' 'subtitle' 'attribution' 'meta' 'scores' 'excerpt';

  border: 1px solid var.$sand;

  border-radius: $border-radius !important;
  margin: 0 0 30px 0;

  box-shadow: none !important;
  @include mix.shiny-bg();
  @include mix.boxshadow($important: true);
  @include mix.ease-it();

  &:hover {
    background: white;
    @include mix.ease-it();
    @include mix.boxshadow-hover($important: true);

    #{$this}__img img {
      transform: scale(1.1, 1.1);
    }

    #{$this}__img-overlay {
      opacity: .5;
      @include mix.ease-it();
    }
  }

  &__icon {
    z-index: $no_link_z;

    #{$this}.icon-has-link & {
      z-index: $has_link_z;
    }

    grid-area: icon;
    justify-self: center;
    margin-top: 15px;
  }

  &__stamps {
    z-index: $no_link_z;

    #{$this}.stamps-has-link & {
      z-index: $has_link_z;
    }

    grid-area: stamps;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin: 5px;
    justify-self: center;
    justify-content: center;
    height: fit-content;

    #{$this}__stamp {
      @include mix.font(600);
      text-align: center;
      @include mix.color-gradient(var.$primary);
      color: white;
      border-radius: var.$border-radius;
      padding: 0 10px;
      //text-transform: uppercase;
      font-size: .7em;
      letter-spacing: 1px;
    }
  }

  &__pt-badge {
    z-index: $no_link_z;

    #{$this}.pt_badge-has-link & {
      z-index: $has_link_z;
    }

    grid-area: pt-badge-headshot;
    justify-self: center;
    margin: 5px;

  }

  &__headshot {
    z-index: $no_link_z;

    #{$this}.headshot-has-link & {
      z-index: $has_link_z;
    }

    grid-area: pt-badge-headshot;
    justify-self: center;
    width: 90%;
    max-width: 200px;

    .contributor__headshot {
      margin: 5px auto;
    }

  }

  &__title {
    z-index: $no_link_z;

    #{$this}.title-has-link & {
      z-index: $has_link_z;
    }

    grid-area: title;
    //text-transform: uppercase;
    text-align: center;
    align-self: end;
    color: var.$primary;
    padding: 15px 20px;
    margin: 0 !important;
    font-size: 1.6em;
    letter-spacing: 1px;
    @include mix.font(900);
    @include mix.hyphenate();

    #{$this}.has-img & {
      color: white;
      @include mix.textshadow();
    }

    #{$this}.no-subtitle & {
      align-self: center;
    }

    @include mix.media-above(var.$lg) {
      #{$this}__sidebars & {
        font-size: 1.2em;
      }
    }
  }

  &__subtitle {
    z-index: $no_link_z;

    #{$this}.subtitle-has-link & {
      z-index: $has_link_z;
    }

    grid-area: subtitle;
    @include mix.font();
    @include mix.hyphenate();
    font-size: 1em;
    color: var.$primary2;
    text-align: center;
    align-self: start;
    margin: -5px 0 10px 0 !important;
    padding: 5px 15px;
    letter-spacing: 1px;
    line-height: 1.4em;

    hr {
      display: none;
    }

    #{$this}.has-img & {
      color: white;
      @include mix.textshadow();

      hr {
        display: block;
        margin: 0 auto;
        border-top: 1px solid var.$gray-500;
        width: 90%;
        padding-bottom: 15px;
      }
    }
  }

  &__attribution {
    z-index: $no_link_z;

    #{$this}.attribution-has-link & {
      z-index: $has_link_z;
    }

    grid-area: attribution;
    margin: 5px;
  }

  &__meta {
    z-index: $no_link_z;

    #{$this}.meta-has-link & {
      z-index: $has_link_z;
    }

    grid-area: meta;
    word-wrap: normal;
    font-size: .7em;
    color: var.$gray-550;
    text-align: center;
    margin: 0;
    padding: 5px 20px;
    @include mix.font(600);
    line-height: 1.5em;


    a {
      padding-bottom: 0 !important;
    }

    &-item {
      padding: 3px 0;
    }

    #{$this}.has-excerpt &, #{$this}.has-scores & {
      border-bottom: 1px solid var.$gray-300;
      border-top: 1px solid var.$gray-300;
    }
  }

  &__scores {
    z-index: $no_link_z;

    #{$this}.scores-has-link & {
      z-index: $has_link_z;
    }

    grid-area: scores;

    #{$this}__sidebars & .scores {

      align-items: flex-start;

      &.with-last-accessed .scores__labels {
        margin-top: 30px + 4px;
      }

      &__labels {
        grid-template-columns:  repeat(2, auto) !important;
      }

      &__label {
        display: contents;

        &--last-accessed .scores__text {
          grid-column: 2 / -1;
          //padding-top: 15px;
          font-size: .6em;
          line-height: 1.2em;
        }
      }
    }
  }

  &__excerpt {
    z-index: $no_link_z;

    #{$this}.excerpt-has-link & {
      z-index: $has_link_z;
    }

    grid-area: excerpt;
    color: var.$gray-550;
    font-size: .9em;
    padding: 15px;
    line-height: 1.5em;

    &-readmore {

      text-align: center;
      background: var.$secondary;
      @include mix.color-gradient(var.$secondary);
      display: inline-block;
      line-height: 2;
      box-shadow: unset !important;
      color: white;
      padding: 0 10px !important;
      @include mix.font(600);
      letter-spacing: .7px;
      font-size: .7em;
      //text-transform: uppercase;
      @include mix.boxshadow(0, 2px, 1px, .2, true);
      border-radius: var.$border-radius;

      &:hover {
        color: white;
        background: var.$secondary-hover;
        @include mix.color-gradient(var.$secondary-hover);
      }
    }
  }

  &__img-overlay {
    border-radius: $border-radius;

    #{$this}.has-author &,
    #{$this}.has-excerpt &,
    #{$this}.has-meta &,
    #{$this}.has-scores & {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    grid-area: 1 / 1 / 7 / -1;
    z-index: $image_over_z;
    @include mix.color-gradient(var.$gray-900);
    @include mix.ease-it();
    opacity: .7;

  }

  &__img {
    z-index: $image_z;
    grid-area: 1 / 1 / 7 / -1;
    border-radius: $border-radius;

    #{$this}.has-author &,
    #{$this}.has-excerpt &,
    #{$this}.has-meta &,
    #{$this}.has-scores & {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      margin: 0;
      @include mix.ease-it();
    }
  }

  &__link {
    z-index: $card_link_z;
    grid-area: 1 / 1 / -1 / -1;
    box-shadow: none !important;
    padding: 0 !important;
  }

  &__authors {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-top: 1px solid var.$border-color;

    #{$this}__author {
      margin: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: .9em;

      &-headshot {
        aspect-ratio: 1/1;
        border: 1px solid var.$sand;
        border-radius: 50%;
        //@include mix.boxshadow(0, 1px, 4px, .4);
        margin: 0 15px 0 0;
        height: 100%;

        img {
          border-radius: 50%;
          object-fit: cover;
          margin: 0;
          height: 100%;
        }
      }

    }

  }

  &__blockcard {
    &.has-img {
      &.has-pt_badge {
        #{$this}__pt-badge {
          margin-top: 40px;
        }
      }

      &.has-headshot {
        #{$this}__headshot {
          margin-top: 40px;
        }
      }

      &.no-pt_badge.no-headshot.no-icon.has-title {
        #{$this}__title {
          margin-top: 40px !important;
        }
      }

      &.has-subtitle {
        #{$this}__subtitle {
          margin-bottom: 40px !important;
        }
      }

      &.no-subtitle.has-title {
        #{$this}__title {
          margin-bottom: 40px !important;
        }
      }

      &.no-title {
        #{$this}__img {
          min-height: 350px !important;
        }
      }
    }

    &-archive.has-title {
      #{$this}__title {
        //font-size: 3em;
      }
    }

  }
}



