@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

.tcc_heading {
  text-align: center;
  margin: 80px 0 20px 0;


  &.tighter {
    margin: 20px 0 0 0;
  }

  h2 {
    font-size: 45px;
    border-bottom: 6px solid var.$mud;
    margin: 15px 0 0 0;
  }

  h3 {
    font-size: 35px;
    border-bottom: 4px solid var.$mud;
    margin: 10px 0 0 0;
  }

  h4 {
    font-size: 25px;
    border-bottom: 2px solid var.$mud;
    margin: 5px 20px 0 20px;
  }

  h2, h3, h4 {
    @include mix.font(600,'true');
    letter-spacing: 1px;
    //text-transform: uppercase;
    color: var.$mud;

    &.blue {
      color: var.$primary;
      border-bottom-color: var.$primary;
    }

    &.yellow {
      color: var.$primary2;
      border-bottom-color: var.$primary2;
    }

    &.gray {
      color: var.$mud;
      border-bottom-color: var.$mud;
    }
  }

  &:first-of-type {
    margin-top: 0; // Change the top margin of the first header element to 0
  }
}