@use "../cc/variables" as var;
@use "../cc/mixins" as mix;
@use "../cc/functions" as fun;

.hero {
  $this: &;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: minmax(120px, auto) 1fr minmax(100px, auto) minmax(20px, auto);

  grid-template-areas:
    "badge"
    "titles"
    "imgAtt"
    "dfMargin";

  &__bg {
    grid-area: 1 / 1 / -1 / -1;
    z-index: 0;
    background: linear-gradient(to top, var.$qdf-2 0, var.$qdf-1 100%) repeat-x;
  }

  &__img {
    grid-area: 1 / 1 / -1 / -1;
    z-index: 1;

    position: relative;

    img {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__overlay {
    grid-area: 1 / 1 / -1 / -1;
    z-index: 2;

    @include mix.color-gradient(var.$gray-900);
    opacity: .8;

    #{$this}.hero--no-img & {
      opacity: .4;
    }
  }

  &__pt-badge {
    grid-area: badge;
    z-index: 4;
    margin: 20px;
    justify-self: center;

    .pt-badge__inner {
      background: fun.color-alpha(var.$white, -.1);
    }

    @include mix.media-above(var.$lg) {
      justify-self: right;
    }

  }

  &__titles {
    z-index: 3;
    grid-area: titles;
    color: white;
    @include mix.textshadow();
    //text-transform: uppercase;
    text-align: center;
    margin: 0 20px;
    align-self: center;

    span.hero__title {
      display: block;
      @include mix.font(650);
      @include mix.hyphenate();
      font-size: 30px;
      letter-spacing: 1.9px;

      @include mix.media-above(var.$sm) {
        font-size: 50px;
      }
      @include mix.media-above(var.$md) {
        font-size: 60px;
      }
      @include mix.media-above(var.$lg) {
        font-size: 70px;
      }
      @include mix.media-above(var.$xl) {
        font-size: 80px;
      }

    }

    span.hero__subtitle {
      display: block;
      @include mix.font(350);
      @include mix.hyphenate();
      font-size: 19px;
      border-top: 1px solid var.$gray-400;
      margin-top: 15px;
      padding-top: 20px;
      letter-spacing: 1.9px;

      @include mix.media-above(var.$sm) {
        font-size: 31px;
      }
      @include mix.media-above(var.$md) {
        font-size: 37px;
      }
      @include mix.media-above(var.$lg) {
        font-size: 43px;
      }
      @include mix.media-above(var.$xl) {
        font-size: 49px;
      }
    }

    @include mix.media-above(var.$lg) {
      #{$this}.hero--has-badge & {
        //margin-bottom: 40px;
      }
    }

  }

  &__image-attribution-btn {
    grid-area: imgAtt;
    z-index: 4;
    justify-self: right;
    margin: 20px;
  }
}









