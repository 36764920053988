@use "../cc/mixins" as mix;
@use "../cc/variables" as var;

.contributor__headshot {
  aspect-ratio: 1/1;
  width: 100%;
  max-width: 350px;
  border: 1px solid var.$sand;
  position: relative;
  border-radius: 50%;
  @include mix.boxshadow(0, 1px, 8px, .8);
  z-index: 98;

  img {
    border-radius: 50%;
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  // the contributor post type headshot
  .contributor > & {
    margin: 0 auto -50px auto;
    top: -100px;
  }

}

.summary {
  color: white;
  border-radius: var.$border-radius;
  @include mix.color-gradient(var.$secondary, -15%, 0%);
  @include mix.boxshadow(1px, 2px, 3px, .5);
  padding: 40px;
  margin: 50px 0;

  ul {
    padding: 0 0 0 20px;
    margin: 0;
    //list-style-type: none;
  }

  li {
    padding: 8px 0;
  }

  a {
    color: white;
    box-shadow: inset 0 -1.2px 0 white !important;

    &:hover {
      box-shadow: inset 0 -2.2px 0 white !important;
    }
  }
}

.summary__heading {
  @include mix.font(900);
  @include mix.textshadow(0, 1px, 2px, .2);
  font-size: 2em;
  margin-left: -20px;
}

.contributions__heading {
  @include mix.font(900);
  margin: 40px 0;
  color: var.$secondary;
}

.contributors__all-link {
  display: grid;
  grid-template-columns: 100px 1fr 100px;
  padding: 20px 0 !important;
  box-shadow: none !important;
  border: 1px solid var.$sand;
  color: var.$secondary;
  @include mix.shiny-bg();
  @include mix.font(600);

  svg {
    fill: var.$secondary;
    align-self: center;
    justify-self: center;
  }

  span {
    align-self: center;
    justify-self: start;
  }

  &:hover {
    svg * {
      fill: var.$secondary-hover;
      @include mix.ease-it();
    }
    color: var.$secondary-hover;
    background: white;
  }
}