@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

.scores-full {
  $block: &;

  .cc-svg__scores {
    padding-right: 10px;

    &__avg-click {
      cursor: pointer;
    }
  }

  &__svg-consensus {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__rating-review {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }

  &__rating-review &__label {
    display: contents;
  }

  &__label {
    display: flex;
    justify-content: center;
    align-items: center;
    @include mix.font(600);

    .scores-full__number {
      padding-right: 10px;
      border-right: 1px solid var.$sand;
    }

    &--consensus .scores-full__number, &--consensus .scores-full__text, &--consensus .scores-full__mean {
      color: var.$secondary;
    }

    &--rating .scores-full__number, &--rating .scores-full__text, &--rating .scores-full__mean {
      color: var.$primary;
    }

    &--review .scores-full__number, &--review .scores-full__text, &--review .scores-full__mean {
      color: var.$primary2;
    }

    .scores-full__mean-dot {
      position: relative;
      font-size: 1.6em;
      padding-right: 3px;
      bottom: -2px;
    }
  }

  &__number {
    @include mix.font(700);
    line-height: 1em;
    font-size: 2em;
    align-self: center;
    justify-self: end;
    grid-column: 1/2;

    & .cc-svg {
      position: relative;
      top: -5px;
    }
  }

  &__text {
    font-size: .8em;
    line-height: 1.1em;
    color: var.$gray-600;
    padding: 10px 15px;
    grid-column: 2/3;
  }

  &__mean {
    font-size: .7em;
    color: var.$gray-500;
  }

  &__src {
    border: 1px solid var.$sand;
    border-radius: var.$border-radius;
    margin-bottom: 20px;
  }

  &__src-inner {
    margin: 20px;

    &--rating {
      #{$block}__src-label, #{$block}__src-number, #{$block}__src-text {
        color: var.$primary
      }
    }

    &--review {
      #{$block}__src-label, #{$block}__src-number, #{$block}__src-text {
        color: var.$primary2
      }
    }

  }

  &__src-label {
    @include mix.font();
    font-size: 1.1em;
    letter-spacing: .5px;
    margin-bottom: 15px;
  }

  &__src-scores {
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
    grid-gap: 0 10px;
  }

  &__src-number {
    font-size: 1.1em;
    @include mix.font();
    grid-column: 1/2;
    justify-self: end;
  }

  &__src-text {
    grid-column: 2/3;
    font-size: .8em;
    line-height: 1.1em;
    color: var.$link-color;
    box-shadow: none !important;
    padding: 0 !important;

    &:hover {
      color: var.$secondary;
    }
  }

  &__last-updated {
    text-align: center;
    font-size: .7em;
    color: var.$gray-500;
    margin-bottom: 20px;
  }

}

@include mix.media-above(var.$md) {

  .scores-full {

    &__rating-review {
      grid-template-columns: auto auto auto;
      // was display content at small screen
      .scores-full__label {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 20px;
      }
    }

    &__src {
      display: flex;
    }

    &__src-inner {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}




