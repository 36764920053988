@use "../cc/variables" as var;

.author-date {
  text-align: center;
  position: relative;
  top: 0;
  color: var.$gray-500;
  padding-bottom: 15px;
  margin-top: 15px;
  font-size: .8em;
}