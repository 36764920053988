@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

.contact-form {

  //border: 1px solid red;
  label {
    margin-bottom: 30px;
    display: block;
    //text-transform: uppercase;
    color: var.$primary;
    @include mix.font();

    span {
      color: var.$warning;
    }

    input, textarea {
      display: block;
      width: 100%;
      padding: 10px;
      color: var.$gray-600;
    }

    textarea {
      height: 200px;
    }
  }

  .g-recaptcha {
    margin-bottom: 30px;
  }

  .submit-btn {
    padding: 20px 40px;
    //text-transform: uppercase;
    background-color: var.$secondary;
    color: white;
    border: none;
    border-radius: var.$border-radius;
    @include mix.boxshadow($opacity: .3);
    @include mix.ease-it();

    .glyphicon {
      margin-left: 25px;
    }

    &:hover {
      @include mix.ease-it();
      opacity: .9;
    }
  }

}

.notice {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 40px 0 10px 0;
  font-size: .7em;

  .glyphicon {
    padding: 4px 15px 0 0;
    font-size: 2.2em;

    color: var.$warning;
  }

  h2 {
    color: var.$warning;
    margin: 0;
    padding: 0;

    small {
      display: block;
      color: var.$gray-400 !important;
      padding-top: 8px;
      font-size: .5em;
    }
  }

  &.thanks {
    h2 {
      color: var.$success;
    }

    .glyphicon {
      color: var.$success;
    }
  }
}

