@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

// degree finder and block
.qdf-wrapper {

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;

  .qdf-heading {
    color: white;
    text-transform: capitalize;
    //letter-spacing: 1px;
    text-align: center;
    line-height: 1.1em;
    white-space: pre-line;
    padding: 15px;
    margin: 0;
    width: 100%;
  }

  .qdf-description {
    margin: -10px 0 10px 0;
    @include mix.font();
    color: var.$white;
    letter-spacing: 1px;
    text-align: center;
    line-height: 1.4em;
    white-space: pre-line;
    font-size: .7em;
    padding: 0 15px;
  }

  .he-qdf {

    --body-font-family: 'Quicksand';
    --widget-padding: 10px;
    --widget-background-color: var.$primary;
    --gray-200: #e4e4e7;
    --gray-500: #71717a;
    --gray-700: #3f3f46;

    // form
    --label-text-transform: capitalize;

    // button
    --button-width: 180px;
    --button-border-radius: 6px;
    --button-background-color: #F34D4A;
    --button-color: white;
    --button-font-family: 'Quicksand';
    --button-font-size: 16px;
    --button-font-weight: 700 !important;
    --button-text-transform: capitalize;
    --button-background-color-hover: #e94340;

    // tooltip
    --sonic-tooltip-body-color: #5a5f58;
    --sonic-tooltip-label-color: #5a5f58;
    --sonic-tooltip-label-font-size: 11px;
    --sonic-tooltip-label-text-decoration: none;
    --sonic-tooltip-label-text-transform: capitalize;
    --sonic-tooltip-label-position: absolute;
    --sonic-tooltip-label-top: initial;
    --sonic-tooltip-label-bottom: -40px;
    --sonic-tooltip-label-right: 1px;
    --sonic-tooltip-label-left: 1px;
    --label-font-weight: "normal";
    --content-color: #5a5f58;
    //--content-font-size: 20px;
    //--body-font-size: 40px;

    box-shadow: 0 1px 1px 2px rgba(0, 0, 0, .2);
    background: var.$primary;
    border-radius: var.$border-radius;
    //background: linear-gradient(to top, var.$qdf-2 0, var.$qdf-1 100%);
    //background-repeat: repeat-x;
    width: 100%;
  }

  // VARIATIONS
  &.qdf-top.qdf-horizontal {
    z-index: 99;
    position: relative;
    top: -115px;
    margin-bottom: -80px;
    min-height: 415px;
    @include mix.media-above(var.$md) {
      min-height: 200px;
    }
  }

  &.qdf-horizontal {

    .he-qdf {
      // make the margin around the botton on full screen horizontal the same
      @include mix.media-above(var.$lg) {
        --widget-padding: 10px 25px 10px 10px;
      }

      @include mix.media-above(var.$xl) {
        width: 90%;
      }
    }
  }

  &.qdf-bottom {
    $bot_height: 700px;
    margin: 100px 0 0 0;
    height: $bot_height;


    .dfbg {
      position: absolute;
      height: $bot_height;
      margin-top: 0;
      width: 100%;
      border-top: 1px solid var.$gray-600;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        @include mix.ease-it();
      }
    }

    .dfbg-overlay {
      @include mix.color-gradient(var.$gray-100);
      height: $bot_height;
      position: absolute;
      width: 100%;
      opacity: .6;
    }

    .qdf-wrapper-inner {
      height: $bot_height;
    }

    .he-qdf {
      --sonic-tooltip-label-bottom: -50px;
      --sonic-tooltip-label-right: 0;
      --sonic-tooltip-label-left: 0;
      --label-font-weight: 700;
      --sonic-tooltip-label-color: #13294c;
    }

    .qdf-titleing {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .qdf-heading {
        color: adjust-color(var.$primary, $lightness: -10%);
        font-size: 3em;
      }

      .qdf-description {
        font-size: 1.2em;
        color: var.$mud;
      }
    }

    .qdf-form {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      @include mix.media-above(var.$md) {
        align-items: center;
      }
    }
  }
}
