@use "../cc/variables" as var;

.interlink {
  &__heading {
    text-align: center;
    color: var.$primary;
    //border-top: 1px solid var.$sand !important;
    margin-top: 60px;
    padding-top: 40px;
  }

  .cc-svg__owl {
    display: block;
    margin: 0 auto 60px auto;
  }
}