@use "../cc/variables" as var;
@use "../cc/mixins" as mix;
@use "../cc/functions" as fun;

$size: 80px;

.pt-badge {

  $block: &;
  pointer-events: none;

  a {
    box-shadow: none !important;
    padding: 0 !important;
    pointer-events: auto;
  }

  .cc-svg {
    display: block;
    margin: 0 auto 4px auto;
  }

  &__link {
    padding: 0;
    margin: 0;
    box-shadow: none;
    display: block;
    height: $size;
    width: $size;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: $size;
    height: $size;
    padding: 8px 16px;
    border: 1px solid fun.color-alpha(var.$sand);
    border-radius: 50%;
    background: var.$off-white;
    font-size: .7em;
    line-height: 1em;
    text-align: center;
    color: var.$mud;
    @include mix.boxshadow();
    @include mix.ease-it();

    &:hover {
      background: white;
      border: 1px solid fun.color-alpha(var.$mud);
      @include mix.ease-it();
    }

    &.active {
      background: white;
      border: 2px solid fun.color-alpha(var.$primary);
      @include mix.ease-it();

      &:hover {
        border: 1px solid fun.color-alpha(var.$primary);
      }
    }
  }

  &--sidebar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    margin-bottom: 40px;

    a {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }

    a:nth-child(even) {
      justify-content: flex-start;
    }
  }
}


