@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

.description {
  margin-bottom: 60px;
  //border: 1px solid var.$sand;
  border-left: 5px solid var.$primary2;
  border-right: 5px solid var.$primary2;
  border-radius: 1.5%;
  padding: 0 20px;
  @include mix.media-above(var.$md) {
    padding: 0 50px;
  }
}