@use "../cc/mixins" as mix;
@use "../cc/variables" as var;

@include mix.media-below(var.$lg) {
  .wp-block-table {

    table {
      width: 100%;
      border-collapse: collapse;
    }

    th, td {
      padding: 8px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }

    table, thead, tbody, th, td, tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
      margin-bottom: 10px;
    }

    td {
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
    }

    td:before {
      //position: absolute;
      //top: 6px;
      //left: 6px;
      //width: 45%;
      padding-right: 10px;

      //white-space: nowrap;
      content: attr(data-label);
      @include mix.font(900);
      color: var.$primary;
    }
  }
}