@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

.stats-meters {
  $bar-height: 14px;
  display: grid;
  grid-template-columns: max-content auto;
  grid-auto-rows: max-content;
  grid-gap: 0 10px;
  align-items: center;
  padding: 20px;

  &__value {
    justify-self: end;
    @include mix.font();
    font-size: 1.6em;
    grid-column: 1 /2;
  }

  &__bar {
    grid-column: 2 /3;
    padding: 12px 0;
  }

  &__outline {
    height: $bar-height;
    border: 1px solid var.$gray-400;
    background-color: white;
    @include mix.boxshadow();
  }

  &__fill {
    height: $bar-height - 2px;
    background: repeating-linear-gradient(
                    55deg,
                    adjust_color(var.$off-white, $lightness: -20%),
                    adjust_color(var.$off-white, $lightness: -25%) 20%,
                    adjust_color(var.$off-white, $lightness: -22%) 50%,
                    adjust_color(var.$off-white, $lightness: -28%) 70%,
                    adjust_color(var.$off-white, $lightness: -33%) 100%
    );
  }

  &__avg {
    margin-top: -$bar-height;
    height: $bar-height;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__tooltip {
    height: $bar-height;
    width: $bar-height;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -($bar-height*0.5);
    margin-top: -2px;
    cursor: pointer;

    .cc-svg {
      position: relative;
      top: 1px;
    }
  }

  &__key {
    @include mix.font();
    font-size: .7em;
    letter-spacing: .5px;
    //padding-top: 2px;
  }

  &__legend {
    grid-column: 1/-1;
    text-align: center;
    font-size: .6em;
    //text-transform: uppercase;

    .cc-svg {
      position: relative;
      top: -1px;
    }
  }

}

.tooltip .arrow {
  display: none;
}

.tooltip-inner {
  color: var.$gray-600;
  background: #fff;
  border: solid 1px var.$gray-600;
}