@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

$grid-column-map: (
        "__1-7": unquote("1 / 7"),
        "__7-13": unquote("7 / 13"),
        "__1-13": unquote("1 / 13"),
);

$grid-column-map-md: (
        "__1-6": unquote("1 / 6"),
        "__6-13": unquote("6 / 13"),
        "__1-8": unquote("1 / 8"),
        "__8-13": unquote("8 / 13"),
);

$grid-column-map-lg: (
        "__1-5": unquote("1 / 5"),
        "__5-9": unquote("5 / 9"),
        "__9-13": unquote("9 / 13"),
        "__5-13": unquote("5 / 13"),
        "__1-9": unquote("1 / 9"),
);

.blockcard-group {
  margin: 30px 0;

  article {
    .cccard {
      height: 100%;
    }
  }

  @include mix.media-above(var.$md) {
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    article.cardcol-md {

      grid-column: auto / span 6;

      @each $name, $val in $grid-column-map-md {
        &#{$name} {
          grid-column: $val;
        }
      }

      @each $name, $val in $grid-column-map {
        &#{$name} {
          grid-column: $val;
        }
      }

    }
  }
}

// only on full with layouts
@include mix.media-above(var.$lg) {
  .content.full-width {
    article.cardcol-lg {
      @each $name, $val in $grid-column-map-lg {
        &#{$name} {
          grid-column: $val;
        }
      }

      @each $name, $val in $grid-column-map {
        &#{$name} {
          grid-column: $val;
        }
      }

    }
  }
}

