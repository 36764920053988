@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

.stats-standard {
  display: grid;
  grid-template-columns: minmax(1px, .8fr) 20px minmax(1.2px, 1fr);
  grid-auto-rows: max-content;
  align-items: center;
  margin-top: -1px; // layer top rule over top of stat box
  &__rule {
    grid-column: 1/-1;
    height: 1px;
    background-color: var.$sand;
  }

  &__key {
    grid-column: 1/2;
    padding: 12px 3px 12px 12px;
    @include mix.font();
    justify-self: end;
    text-align: right;
  }

  &__caret {
    grid-column: 2/3;
  }

  &__value {
    padding: 12px 12px 12px 0;
    grid-column: 3/4;
    justify-self: start;
    word-break: break-word;

    ul {
      margin: 0;
    }
  }
}