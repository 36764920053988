@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

.rank {

  $block: &;

  content-visibility: auto;
  contain-intrinsic-size: 500px;
  clear: both;
  border: 1px solid var.$sand;
  border-radius: var.$border-radius;
  margin: 0 0 50px 0;
  @include mix.boxshadow();

  &__head {
    position: relative;
  }

  &__header {
    display: grid;
    grid-template-columns: max-content minmax(0, 1fr);
    border-top-left-radius: var.$border-radius - 1;
    border-top-right-radius: var.$border-radius - 1;
    @include mix.shiny-bg();
  }

  .rank__number {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 20px 30px;
    color: white;
    letter-spacing: 2px;
    font-size: 3em;
    border-top-left-radius: var.$border-radius - 1;
    @include mix.color-gradient(var.$primary2);
    @include mix.textshadow();
    @include mix.font(350);
  }

  &__title {
    padding: 20px 30px;
    margin: 0 !important;
    font-size: 1.8em;
    letter-spacing: 1px;
    color: var.$primary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include mix.font(600);
  }

  &__sub-title {
    font-size: .65em;
    margin-top: 6px;
    color: adjust-color(var.$primary, $lightness: 5%);
  }

  &__location {
    font-size: .45em;
    margin-top: 8px;
    color: adjust-color(var.$black, $lightness: 55%);
  }

  &__image {
    height: 300px;
    overflow: hidden;

    img {
      margin: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__link {
    display: block;
    padding: 0 !important;
    box-shadow: none !important;

    #{$block}__title, #{$block}sub-title {
      @include mix.ease-it();
    }

    #{$block}__image img {
      opacity: .9;
      @include mix.ease-it();
    }

    &:hover {
      #{$block}__title, #{$block}sub-title {
        color: var.$primary2;
        @include mix.ease-it();
      }

      #{$block}__image img {
        opacity: 1;
        @include mix.ease-it();
      }
    }
  }

  &__body {
    margin: 30px;
  }

  &__image_logo {
    text-align: center;
  }

  &__stats {
    padding: 30px;
    font-size: .8em;
    letter-spacing: .5px;
    line-height: 1.3em;
    display: grid;
    grid-template-columns: auto 20px auto;
    grid-auto-rows: max-content;
    align-items: center;
    border-top: 1px solid var.$sand;
    background: repeating-linear-gradient(
                    -55deg,
                    var.$off-white,
                    var.$off-white 150px,
                    adjust_color(var.$off-white, $lightness: -.5%) 150px,
                    adjust_color(var.$off-white, $lightness: -1.5%) 300px
    );

    a {
      padding: 0 !important;
      box-shadow: none !important;
    }
  }

  &__stats-row {
    display: contents;
  }

  &__stats-key {
    grid-column: 1/2;
    padding: 12px 3px 12px 12px;
    @include mix.font();
    justify-self: end;
    text-align: right;
  }

  &__stats-caret {
    grid-column: 2/3;
  }

  &__stats-value {
    padding: 12px 12px 12px 0;
    grid-column: 3/4;
    justify-self: start;

    ul {
      margin: 0;
    }
  }

  &__tabs-head {
    a {
      padding: 10px 20px !important;
      box-shadow: none !important;
    }
  }

  &__tabs-content {
    margin-top: 20px;
  }
}

@include mix.media-above(var.$md) {

  .rank {
    &__image_logo.has_body_copy {
      width: 200px;
      margin-left: 20px;
      float: right;
    }
  }
}