@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

.team {
  margin: 100px 0 0 0;
  text-align: center;

  h3 {
    //text-transform: uppercase;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 1.1em;
    letter-spacing: 2px;
    margin-top: 50px;
    color: var.$primary;
  }

  h4 {
    //text-transform: uppercase;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: .7em;
    letter-spacing: 2px;
    margin: 0;
    color: adjust_color(var.$primary, $lightness: 30%);
  }

  img {
    border-radius: var.$border-radius;
  }

  &__bio {
    text-align: left;
    //font-size: .8em;
    margin-top: 15px;
  }
}