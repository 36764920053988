@use "../cc/variables" as var;
@use "../cc/mixins" as mix;
@use "../cc/functions" as fun;

.cc-btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 40px 0;

  // no link (disabled)
  > button {
    background-color: fun.color-alpha(var.$sand, -.7);
    color: var.$sand;
    border: none;
    @include mix.font();
    cursor: default;
  }

  a {
    display: block;
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
    @include mix.font();

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: .9em;
      padding: 0;
      margin: 0 5px 10px 5px;
      width: 50px;
      height: 50px;
      background-color: fun.color-alpha(var.$sand, -.7);
      border: 1px solid var.$sand;
      color: var.$mud;
      cursor: pointer;

      &:hover {
        background-color: var.$primary;
        color: white;
      }

    }

    &.on {
      button {
        background-color: var.$primary;
        color: white;
      }
    }
  }

  .spacer {
    visibility: hidden;
    height: 0 !important;
    max-height: 0 !important;
    margin: 0 5px;
    width: 50px;
  }
}