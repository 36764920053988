@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

.program-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 10px;
  justify-content: stretch;
  align-items: stretch;

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px !important;
    border: 1px solid var.$sand;
    border-radius: var.$border-radius;
    background-color: var.$off-white;
    box-shadow: none !important;
    line-height: 1.2em;
    font-size: .9em;
    @include mix.font();
    @include mix.boxshadow($important: true);

    &:hover {
      background-color: white;
    }
  }
}