@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

// no margin for first thing in sticky
.sticky-sidebar > :first-child {
  margin-top: 0 !important;
}

.sidebar {
  margin-top: 60px;
  @include mix.media-above(var.$lg) {
    margin-top: 0;
  }
}

.sticky-sidebar {
  z-index: 999999;
}

.sidebar_heading {
  //text-transform: uppercase;
  letter-spacing: 1px;
  @include mix.font();
  @include mix.hyphenate();
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  line-height: 1em;
  text-align: center;
  color: var.$primary;
  margin-bottom: 15px;

  .cc-svg {
    margin-top: 10px;
  }
}