@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

$grid-row-height: 30px;
$grid-row-gap: 4px;

.cc-svg__scores {
  overflow: visible;
  text{
    @include mix.font(700);
    font-size: 1.5em;
  }
}

.scores {
  display: flex;
  place-content: center;
  justify-content: center;
  align-items: center;
  margin: 20px;

  .cc-svg__scores {
    //margin-top: -20px;
    padding-right: 8px;
  }

  &__labels {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-auto-rows: $grid-row-height;
    grid-gap: $grid-row-gap 20px;
  }

  &__number {
    justify-self: end;
    align-self: center;
    @include mix.font();
    line-height: 1em;
    margin-right: -10px;
    font-size: .8em;
  }

  &__text {
    align-self: center;
    font-size: .8em;
    line-height: 1em;
    //color: var.$gray-600;
  }

  &__label {
    display: contents;

    &--consensus {
      color: var.$secondary;

      .scores__number {
        font-size: 1.2em;
      }
    }


    &--rating {
      color: var.$primary;

      .scores__number {
        font-size: 1.2em;
      }
    }

    &--review {
      color: var.$primary2;

      .scores__number {
        font-size: 1.2em;
      }
    }

    &--last-accessed .scores__text {
      grid-column: 2 / -1;
      //padding-top: 15px;
      font-size: .6em;
      line-height: 1.2em;
    }
  }
}

.last-updated {
  font-size: .6em;
  color: var.$gray-500;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@include mix.media-above(var.$md) {

  .scores {

    align-items: center;

    .cc-svg__scores {
      margin-top: 0;
    }

    &.with-last-accessed .scores__labels {
      margin-top: $grid-row-height + $grid-row-gap;
    }

    &__labels {
      grid-template-columns: repeat(6, auto);
    }

    &__label {
      display: contents;

      &--last-accessed .scores__text {
        justify-self: center;
        grid-column: 1 / -1;
      }
    }

  }
}


