@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

.share {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
  border: 1px solid var.$sand;
  border-radius: var.$border-radius;
  overflow: hidden;
  @include mix.boxshadow();

  &__btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 2;
    background-color: var.$off-white;
    padding: 10px 20px;

    &:first-child {
      border-right: 1px solid var.$sand;
    }

    &:hover {
      @include mix.ease-it();
      background-color: var.$white;

      .label {
        @include mix.ease-it();
        color: var.$gray-800;
      }
    }
  }

  &__label {
    font-size: .8em;
    color: var.$gray-600;
  }

  .cc-svg {
    margin-left: 10px;
    fill-opacity: .6;
  }
}

@include mix.media-above(var.$md) {
  .share__label {
    font-size: .6em;
  }
}