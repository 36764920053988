@use "variables" as var;
@use "functions";

@mixin ease-it() {
  transition: all .35s ease;
}

@mixin color-gradient($color, $top-lightness:-10%, $bottom-lightness:10%) {
  background: $color;
  background: linear-gradient(to top, adjust-color($color, $lightness: $top-lightness) 0, adjust-color($color, $lightness: $bottom-lightness) 100%);
  background-repeat: repeat-x;
}

@mixin textshadow($x:0,$y:2px,$spread:4px,$opacity: .4,$important: false) {

  $shadow: $x $y $spread rgba(0, 0, 0, $opacity);

  @if $important {
    text-shadow: $shadow !important;
  } @else {
    text-shadow: $shadow;
  }

}

@mixin boxshadow($x:0,$y:3px,$spread:1px,$opacity: .1,$important: false) {

  $shadow: $x $y $spread rgba(0, 0, 0, $opacity);

  @if $important {
    box-shadow: $shadow !important;
  } @else {
    box-shadow: $shadow;
  }

}

@mixin boxshadow-hover($x:0,$y:5px,$spread:1px,$opacity: .1,$important: false) {

  $shadow: $x $y $spread rgba(0, 0, 0, $opacity);

  @if $important {
    box-shadow: $shadow !important;
  } @else {
    box-shadow: $shadow;
  }

}

@mixin font($wght:500,$important:'') {
  @if $important == '' {
    font-weight: $wght;
    text-rendering: optimizeLegibility;
    font-display: block;
  } @else {
    font-weight: $wght !important;
    text-rendering: optimizeLegibility !important;
    font-display: block !important;
  }
}

@mixin btns() {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 40px 0;

  // no link (disabled)
  > button {
    background-color: functions.color-alpha(var.$sand, -.7);
    color: var.$sand;
    border: none;
    @include font();
    cursor: default;
  }

  a {
    display: block;
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
    @include font();

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: .9em;
      padding: 0;
      margin: 0 5px 10px 5px;
      width: 50px;
      height: 50px;
      background-color: functions.color-alpha(var.$sand, -.7);
      border: 1px solid var.$sand;
      color: var.$mud;
      cursor: pointer;

      &:hover {
        background-color: var.$primary;
        color: white;
      }

    }
  }

  .spacer {
    visibility: hidden;
    height: 0 !important;
    max-height: 0 !important;
    margin: 0 5px;
    width: 50px;
  }
}

@mixin hyphenate() {
  text-wrap: balance;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

@mixin media-above($name) {
  @media (min-width: $name) {
    @content;
  }
}

@mixin media-below($name) {
  @media (max-width: $name - .02) {
    @content;
  }
}

@mixin shiny-bg() {
  transition: all .35s ease;
  background: repeating-linear-gradient(
                  -55deg,
                  adjust_color(var.$primary2, $lightness: 50%) 0%,

                  adjust_color(var.$off-white, $lightness: -1%) 20%,
                  var.$off-white 20%,

                  adjust_color(var.$off-white, $lightness: 1%) 40%,
                  var.$off-white 40%,

                  adjust_color(var.$off-white, $lightness: -3%) 60%,
                  adjust_color(var.$off-white, $lightness: -2%) 60%,

                  adjust_color(var.$off-white, $lightness: 1%) 80%,
                  var.$off-white 80%,

                  adjust_color(var.$primary2, $lightness: 49%) 100%,
  );
}