@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

.cc-badge {
  display: flex;
  margin: 0 auto 30px auto !important;
}

@include mix.media-above(var.$lg) {
  .cc-badge {
    display: block;
    position: relative;
    float: right;
    top: -128px;
    margin-bottom: -128px !important;
    margin-left: 20px !important;

    &.no-offset {
      top: 0;
      margin-bottom: 30px !important;
    }

  }
}