@use "../cc/variables" as var;
@use "../cc/functions" as fun;

@keyframes fill-gray-to-blue {
  from {
    fill: #D3D3D3;
  }
  to {
    fill: var.$primary;
  }
}

.tcc_us_map {

  h2.us_map_head {
    //text-transform: uppercase;
    text-align: center;
    color: var.$primary;
    margin-top: 100px !important;
  }

  // the map
  .usmap {

    // disabled
    path, circle {
      fill: fun.color-alpha(var.$sand, -.7);
    }

    // enabled
    a {
      display: inline-block;
      padding: 0 !important;
      box-shadow: none !important;

      path, circle {
        fill: var.$sand;
      }

      &.on path, &.on circle, :hover path, :hover circle {
        fill: var.$primary;
        animation: fill-gray-to-blue .35s forwards;
      }

    }

  }

}