@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

.tcc-link {
  &__score {
    @include mix.font();
    color: adjust-color(var.$mud, $lightness: 20%) !important;
    font-size: .8em;
    position: relative;
    padding-left: 4px;
    top: -1px
  }

  .cc-svg {
    position: relative;
    top: -1px;
    left: 6px;
    margin-right: 5px;
  }
}