@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

.stats-collections {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  justify-content: stretch;
  align-items: stretch;
  font-size: .8em;
  letter-spacing: .5px;
  line-height: 1.3em;

  &__card {
    position: relative;
    border: 1px solid var.$sand;
    border-radius: var.$border-radius;
    background-color: var.$off-white;
    box-shadow: none !important;
    @include mix.boxshadow($important: true);

    .heading {
      word-wrap: normal;
      text-align: center;
      color: var.$mud !important;
      padding: 15px 20px;
      margin: 0;
      //text-transform: uppercase;
      font-size: 1.1em;
      letter-spacing: 1px;
      background-color: var.$white;
      @include mix.font();
    }

    .subheading {
      background-color: var.$white;
      @include mix.font();
      word-wrap: normal;
      font-size: .7em;
      color: var.$gray-500;
      text-align: center;
      margin: -5px 0 0 0;
      padding: 0 15px 12px 15px;
      letter-spacing: 1px;
      line-height: 1.4em;
    }

    .stats-meters__legend {
      display: none;
    }

  }


}