@use "../cc/variables" as var;

.cc-section {
  margin: 50px 0 25px 0;

  &__heading {
    color: var.$primary;
    //text-transform: uppercase;
    font-size: 1.7em;
    line-height: 1.5em;
    letter-spacing: 1.5px;
    border-bottom: 1px solid var.$sand;

    .cc-svg {
      padding-right: 15px;
      position: relative;
      top: -4px;
    }
  }

}