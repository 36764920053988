@use "../cc/variables" as var;

// editorial listing
.he-el-wrapper {
  margin: 50px 0 50px 0;

  .he-el {

    &__heading {
      color: var.$primary;
      //text-transform: uppercase;
      letter-spacing: 1px;
      text-align: center;
      line-height: 1.1em;
      white-space: pre-line;
      padding: 15px;
      margin: 0;
      width: 100%;
    }

    &__description {
      text-align: center;
      margin: -10px 0 10px 0;
    }

    --body-font-family: 'Quicksand';
    --body-color: #5a5f58;
    --program-color: #1F4F54;
    --school-color: #24A0C3;
    --school-color-hover: #6ca1c2;
    --link-color: #6ca1c2;
    --link-color-hover: #1a92ce;
    --button-background-color: #F34D4A;
    --button-background-color-hover: #e94340;
    --button-border-radius: 3px;
    --button-color: white;
    --button-font-family: 'Quicksand';
    --button-font-size: 17px;
    --sonic-tooltip-body-color: #5a5f58;
    --sonic-tooltip-label-position: absolute;
    --sonic-tooltip-label-right: 0;
    --sonic-tooltip-label-bottom: 0;
    //--sonic-tooltip-label-color: red;
    //--sonic-tooltip-label-font-size: 13px;
    //--sonic-tooltip-label-text-decoration: underline;
    //--sonic-tooltip-label-text-transform: none;
    //--sonic-tooltip-label-top: 15px;
  }
}