@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

.wp-block-quote {
  max-width: 100%;
  text-align: center;
  margin: 40px 40px;
  padding: 20px;
  font-size: 1.4em;
  line-height: 1.4em;
  color: var.$primary;
  border-left: 4px solid var.$sand;
  border-right: 4px solid var.$sand;
  border-radius: 15px;
}

.wp-block-quote cite {
  font-size: 14px !important;
}

.cc-content__article-inner h2 {
  margin-top: 36px;
}

.cc-content__article-inner h3 {
  margin-top: 24px;
}

.wp-block-table {

  overflow-x: visible;

  table {
    width: 100%;
    margin: 30px 0;
    color: var.$mud;
    background-color: var.$off-white;
    border-bottom: 1px solid var.$sand;
    @include mix.boxshadow();


    th,
    td {
      padding: 15px 20px;
      vertical-align: middle;
    }

    thead, tfoot {
      //@include mix.font-normal-bold();
      @include mix.color-gradient(var.$primary);
      color: white;
      //text-transform: uppercase;
      line-height: 1.2em;

      tr {
        border: 1px solid var.$primary;
      }

      //text-align: center;
    }

    thead {
      //border-bottom: 2px solid var.$sand;
      border: none !important;
    }


    tr {
      border-top: 1px solid var.$sand;
      border-right: 1px solid var.$sand;
      border-left: 1px solid var.$sand;
    }


    tfoot {
      border-top: 2px solid var.$sand;
    }

  }

  &.is-style-stripes tbody tr:nth-child(odd) {
    background-color: adjust-color(var.$primary, $lightness: 70%);
  }

  figcaption {
    margin: -20px 0 20px 0;
    font-size: .8em;
    text-align: center;
  }
}