@use "../cc/variables" as var;
@use "../cc/mixins" as mix;
@use "../cc/functions" as fun;

$border-radius: var.$border-radius * 2;

$ranking-card: '.ranking-card';

// the card get's wrapped in a col element with all the identifying clases
#{$ranking-card}-wrapper {

  // set the default border radius all around
  #{$ranking-card} {
    border-radius: $border-radius;

    #{$ranking-card}__image,
    #{$ranking-card}__image img,
    #{$ranking-card}__link:hover #{$ranking-card}__image,
    #{$ranking-card}__link:active #{$ranking-card}__image,
    #{$ranking-card}__content-top {
      border-radius: $border-radius;
    }

  }

  &.has-ranks,
  &.has-badge,
  &.has-excerpt {
    #{$ranking-card}__image,
    #{$ranking-card}__image img,
    #{$ranking-card}__link:hover #{$ranking-card}__image,
    #{$ranking-card}__link:active #{$ranking-card}__image,
    #{$ranking-card}__content-top {
      border-radius: $border-radius $border-radius 0 0 !important;
    }

    #{$ranking-card}__ranks-inner {
      border-radius: 0 0 $border-radius $border-radius !important;
    }
  }

  @include mix.media-above(var.$md) {

    &.has-ranks,
    &.has-badge,
    &.has-excerpt {
      #{$ranking-card}__image,
      #{$ranking-card}__image img,
      #{$ranking-card}__link:hover #{$ranking-card}__image,
      #{$ranking-card}__link:active #{$ranking-card}__image {
        border-radius: $border-radius 0 0 $border-radius !important;
      }

      #{$ranking-card}__ranks-inner {
        border-radius: 0 $border-radius $border-radius 0 !important;
      }

      &.has-badge #{$ranking-card}__content-top {
        border-radius: $border-radius 0 0 0 !important;
      }

      &.no-badge #{$ranking-card}__content-top {
        border-radius: $border-radius 0 0 $border-radius !important;
      }

      #{$ranking-card}__content-bottom {
        border-radius: 0 0 0 $border-radius !important;
      }
    }

  }

  @include mix.media-above(var.$lg) {

    &.has-ranks,
    &.has-badge,
    &.has-excerpt {
      &.size-1 {
        #{$ranking-card}__image,
        #{$ranking-card}__image img,
        #{$ranking-card}__link:hover #{$ranking-card}__image,
        #{$ranking-card}__link:active #{$ranking-card}__image,
        #{$ranking-card}__content-top {
          border-radius: $border-radius $border-radius 0 0 !important;
        }

        #{$ranking-card}__ranks-inner {
          border-radius: 0 0 $border-radius $border-radius !important;
        }
      }

      &.size-2 {
        #{$ranking-card}__content-top {
          border-radius: $border-radius 0 0 $border-radius !important;
        }
      }

      &.size-2, &.size-3 {

        #{$ranking-card}__image,
        #{$ranking-card}__image img,
        #{$ranking-card}__link:hover #{$ranking-card}__image,
        #{$ranking-card}__link:active #{$ranking-card}__image {
          border-radius: $border-radius 0 0 $border-radius !important;
        }

        #{$ranking-card}__ranks-inner {
          border-radius: 0 $border-radius $border-radius 0 !important;
        }

      }

      &.size-3 {
        &.has-badge #{$ranking-card}__content-top {
          border-radius: $border-radius 0 0 0 !important;
        }

        &.no-badge #{$ranking-card}__content-top {
          border-radius: $border-radius 0 0 $border-radius !important;
        }

        #{$ranking-card}__content-bottom {
          border-radius: 0 0 0 $border-radius !important;
        }

      }
    }


  }


}

#{$ranking-card} {
  height: 100%;
  border: 1px solid var.$sand;
  @include mix.ease-it();
  @include mix.boxshadow();

  &:hover {
    @include mix.boxshadow-hover();
  }

  // contains the image and titles and content, basically everything but the rank preview
  &__link {
    //grid-row: 1/2;
    box-shadow: none !important;
    padding: 0 !important;
    display: block;
    position: relative;

    // the image
    #{$ranking-card}__image {
      overflow: hidden;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      height: 100%;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        margin: 0;
        @include mix.ease-it();
      }
    }

    // the contents both top and bottom
    #{$ranking-card}__content {
      position: relative;
      z-index: 2;
      height: 100%;
      display: grid;
      grid-template-columns: auto;
      align-items: center;

      #{$ranking-card}__content-top, #{$ranking-card}__content-bottom {
        @include mix.ease-it();
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
      }

      #{$ranking-card}__content-top {
        background: rgba(0, 0, 0, .5);

        #{$ranking-card}__title {
          @include mix.font(550);
          color: white;
          font-size: 2.4em;
          line-height: 1.2em;
          letter-spacing: 1px;
          @include mix.textshadow();
        }

        #{$ranking-card}__subtitle {
          color: white;
          font-size: 1.4em;
          line-height: 1.2em;
          letter-spacing: 1px;
          padding-top: 10px;
          @include mix.textshadow();
        }

      }

      #{$ranking-card}__content-bottom {
        background: rgba(0, 0, 0, .8);

        #{$ranking-card}__badge {
          text-align: center;

          img {
            margin: 0 15px 30px 15px;
          }
        }

        #{$ranking-card}__excerpt {
          color: var.$off-white;
        }
      }
    }

    &:hover, &:active {

      #{$ranking-card}__content-top, #{$ranking-card}__content-bottom {
        background: adjust-color(var.$primary, $alpha: -.3, $lightness: -20%, $saturation: 100%);

        @include mix.ease-it();
      }

      #{$ranking-card}__image {
        img {
          transform: scale(1.1, 1.1);
          @include mix.ease-it();
        }
      }
    }
  }

  // the ranking previews
  $header_height: 400px;
  $rank_height: 250px;

  &__ranks {
    position: relative;

    #{$ranking-card}__ranks-inner {
      max-height: $rank_height;
      overflow-y: hidden;

      #{$ranking-card}__rank-link {
        display: flex;
        align-items: center;
        padding: 0 10px !important;
        box-shadow: none !important;
        border-bottom: 1px solid var.$border-color;
      }

      #{$ranking-card}__rank-number {
        font-size: 2.2em;
        @include mix.font(200);
        padding: 5px 10px;
        color: var.$primary2;
      }

      #{$ranking-card}__rank-title {
        font-size: .9em;
        padding: 10px;
        line-height: 1.2em;
        letter-spacing: 0.5px;
        color: var.$primary;
      }
    }

    #{$ranking-card}__ranks-footer {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      pointer-events: none;

      a {
        pointer-events: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 140px;
        height: 70px;
        padding: 0 0 10px 0 !important;
        border: 1px solid var.$sand;
        border-top-left-radius: 70px;
        border-top-right-radius: 70px;
        border-bottom: none;
        background-color: fun.color-alpha(var.$secondary, $alpha-level: -.2);
        font-size: 1.2em;
        line-height: 1em;
        text-align: center;
        //text-transform: uppercase;
        letter-spacing: 1px;
        color: white;
        @include mix.font(600);
        @include mix.textshadow($y: 1px, $spread: 2px);
        @include mix.boxshadow($y: -2px, $important: true);

        &:hover {
          background-color: fun.color-alpha(var.$secondary, $alpha-level: -.05);
        }
      }
    }

  }


  @include mix.media-above(var.$md) {

    // put ranks on left for all
    .has-ranks & {
      display: grid;
      grid-template-columns: 2fr 1fr;
      min-height: $rank_height;

      &__ranks-inner {
        max-height: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }
    }
  }

  @include mix.media-above(var.$lg) {

    .size-1 & {
      // hack for large titles
      &__content {
        display: block;
      }

      &__title, &__subtitle {
        letter-spacing: 1px;
      }

      // make all the small ones the some height tops
      &__content-top {
        height: $header_height;
      }

    }

    // put ranks on left for non-small onmes
    .has-ranks:not(.size-1) & {
      grid-template-columns: auto 298px;
    }

    // reverse it back
    .has-ranks.size-1 & {
      display: block;
      min-height: $header_height;

      &__ranks-inner {
        max-height: $rank_height; //not cool when a size 2 is next to it
        position: relative;
      }
    }

    // if a single is with a double, make ranks as high as necessary
    .has-size-1.has-size-2 .has-ranks.size-1 & {
      &__ranks-inner {
        max-height: 400px; // tudu: <-- bad solution
        min-height: $rank_height;
      }

    }
  }

  @include mix.media-above(var.$xl) {

    // put ranks on left for non-small onmes
    .has-ranks:not(.size-1) & {
      grid-template-columns: auto 358px;
    }
  }
}
