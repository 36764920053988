@use "../cc/mixins" as mix;
@use "../cc/variables" as var;

// the list of contributors of a page
.contributors {

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(270px, 100%), 1fr));
  //grid-template-columns: repeat(auto-fit, 1fr);
  gap: 10px;

  &__footer {
    //text-align: center;
    position: relative;
    font-size: .8em;
    @include mix.font(550);
    color: var.$primary;
    //margin-top: 8px;
    padding-top: 8px;
    margin-bottom: 40px;
    border-top: 1px solid var.$sand;
    margin-top: -1px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;

    svg {
      position: relative;
      top: 3px;
    }
  }

  &__count-1 {
    width: max-content;

  }

  &__contributor {
    &-source {
      //width: max-content;
      border: 1px solid white;
      //@include mix.shiny-bg();
      padding: 5px 20px 5px 5px;
      cursor: pointer;
      height: 100%;
      display: grid;
      grid-template-columns: 50px 1fr;
      grid-template-rows: auto auto;

      &:not(.collapsed) {
        border: 1px solid var.$sand;
        border-top-left-radius: var.$border-radius;
        border-top-right-radius: var.$border-radius;
        @include mix.shiny-bg();
        //@include mix.boxshadow();
        //background: white;
        //box-shadow: inset 0 -6px 0 var.$secondary;
      }
    }


    &-headshot {
      grid-area: 1 / 1 / 3 / 2;
      width: 50px;
      align-self: center;
      justify-self: center;

      div {
        box-shadow: unset;
      }
    }

    &-name {
      grid-area: 2 / 2 / 3 / 3;
      @include mix.font(550);
      font-size: .9em;
      align-self: start;
      justify-self: start;
      margin-left: 10px;
      color: var.$primary2;
    }

    &-roles {
      grid-area: 1 / 2 / 2 / 3;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin: 5px 5px 0 10px;
      justify-self: start;
      align-self: end;
      justify-content: start;
      height: fit-content;
    }

    &-role {
      @include mix.font(550);
      text-align: center;
      @include mix.color-gradient(var.$primary);
      color: white;
      border-radius: var.$border-radius;
      padding: 0 10px;
      //text-transform: uppercase;
      font-size: .7em;
      letter-spacing: 1px;
    }

    &-target {

      border: 1px solid var.$sand;
      margin-top: -1px;
      border-bottom-left-radius: var.$border-radius;
      border-bottom-right-radius: var.$border-radius;
      @include mix.shiny-bg();
      padding: 20px;
      position: absolute;
      left: 0;
      right: 0;
      margin-left: 15px;
      margin-right: 15px;
      //background: white;
      z-index: 99;
      @include mix.media-above(var.$md) {
        margin-left: 100px;
        margin-right: 100px;
        box-shadow: inset 0 8px 10px -8px var.$sand, 0 4px 4px rgba(0, 0, 0, .1);
      }
      @include mix.media-above(var.$lg) {
        margin-left: auto;
        margin-right: auto;
        width: 800px;
      }

    }

    &-subtitle {
      text-align: center;
      color: var.$primary2;
      @include mix.font(900);
      font-size: .9em;
    }

    &-education {
      text-align: center;
      color: var.$primary2;
      @include mix.font(900);
      font-size: .9em;
    }

    &-description {
      @include mix.font(530);
      font-size: .9em;
    }

    &-links {
      line-height: 1.2em;
      margin: 20px -20px -20px;

      a {
        display: grid;
        grid-template-columns: 80px 1fr 80px;
        padding: 20px 0;
        color: var.$secondary;
        border-top: 1px solid var.$sand;
        @include mix.font(530);
        font-size: .9em;
        align-items: center;
        @include mix.ease-it();
        background: white;

        svg {
          justify-self: center;
          padding: 0 5px;
          fill: var.$secondary;
        }

        span {
          padding-right: 15px;
          justify-self: start;
        }

        &:hover {
          color: var.$secondary-hover;

          svg * {
            fill: var.$secondary-hover;
            @include mix.ease-it();
          }
        }

      }

      @include mix.media-above(var.$md) {
        //display: grid;
        //grid-template-columns: 1fr 1fr;
        //a {
        //  &:last-child {
        //    border-left: 1px solid var.$sand;
        //  }
        //}
      }
    }

  }

}