@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

.tcc-programs > h2.program_head {
  //text-transform: uppercase;
  text-align: center;
  color: var.$primary;
  margin-top: 0;
}

.tcc-programs > h2.program_head ~ h2.program_head {
  margin-top: 100px !important;
}


.featured_degrees {

  $fd: &;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-gap: 15px;
  justify-content: stretch;
  align-items: stretch;
  //border: 1px solid green;

  &__item {
    //border: 1px solid green;
    display: flex;
    flex-direction: column;

    box-shadow: none !important;
    padding: 5px !important;
    color: var.$mud;

    &:hover {
      background-color: var.$off-white;
    }

    #{$fd}__icon {
      width: 100%;
      height: 80px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;
    }

    #{$fd}__title {
      border-top: 1px solid var.$sand;
      color: var.$primary;
      @include mix.font();
      font-size: 1em;
      line-height: 1.3em;
      text-align: center;
      margin-top: 15px;
      padding-top: 15px;
      margin-bottom: 8px;
    }

    #{$fd}__programs, #{$fd}__cost {
      padding: 5px 0;
      font-size: .8em;
      line-height: 1.2em;
      text-align: center;

      span {
        @include mix.font();
        font-size: .78em;
      }
    }

    #{$fd}__spacer {
      flex-grow: 2;
      border-bottom: 1px solid var.$sand;
      margin-top: 15px;
    }

    i {
      position: relative;
      top: -7px;
      text-align: center;
      color: var.$sand;
    }
  }

}

.jump-to {
  @include mix.font();
  font-size: .8em;
  color: var.$mud;
  margin: 30px 0 -30px 6px;
}

// a program item
.tcc-program {

  $this: &;

  margin-top: 30px;
  //border: 1px solid var.$sand;

  a {
    padding: 0 !important;
    display: block;
    box-shadow: none !important;

    &:hover {
      background-color: var.$off-white;
      border-radius: var.$border-radius;
      @include mix.boxshadow(1px, 2px, 3px, .2, true);

      #{$this}__head h3 {
        color: var.$primary2;
        @include mix.ease-it();
      }

      #{$this}__stats #{$this}__label {
        color: var.$primary2;
        @include mix.ease-it();
      }
    }


  }

  &__head {
    display: grid;
    grid-template-columns:  repeat(6, 1fr);
    gap: 15px;
    padding: 15px 15px 0 15px;

    h3 {
      grid-column: 2 / -1;
      grid-row: 1 / 2;
      color: var.$primary;
      margin: 0 !important;
      align-self: end;
    }

    p {
      grid-column: 1 / -1;
      grid-row: 2 / 3;
      color: var.$mud;
      line-height: 1.3em;
      font-size: 16px;
      margin: 0 !important;
      align-self: end;
    }

    .cc-svg {
      grid-column: 1 / span 1;
      grid-row: 1 / 2;
      width: 100%;
      justify-self: center;
      align-self: end;
    }

  }

  &__stats {
    padding: 0 15px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    margin: 10px -5px;

    #{$this}__stat {
      border: 1px solid var.$sand;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      //text-transform: uppercase;
      padding: 10px;
      margin: 5px;

      #{$this}__label {
        color: var.$mud;
        @include mix.font();
        font-size: .7em;
        line-height: 1.3em;
      }

      #{$this}__value {
        @include mix.font();
        color: var.$primary;
        font-size: 1.3em;
        line-height: 1.3em;
        margin-top: 5px;
      }
    }
  }

  &__foot {
    padding: 0 15px 15px 15px;

    p {
      color: var.$mud;
      line-height: 1.3em;
      font-size: 16px;
      margin: 0 !important;
    }

    strong {
      @include mix.font();
    }
  }

  @include mix.media-above(var.$md) {

    &__head {
      grid-template-columns:  4fr 1fr;
      grid-template-rows: 1fr auto;

      h3 {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }

      p {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
      }

      .cc-svg {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        padding-bottom: 15px;
        width: 60%;
        align-self: end;
        justify-self: center;
      }
    }

    &__stats {
      margin: 0;

      #{$this}__stat {
        margin: 0;

        &:not(:last-child) {
          border-right: none;
          margin: 15px 0;
        }
      }
    }

    &__foot {
      display: grid;
      gap: 6px;
      grid-template-columns:  4fr 1fr;

      p {
        grid-column: 1 / 2;
      }
    }

  }
}