@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

// lets see the navbar with the wp admin bar
#wpadminbar {
  z-index: 99999999999999999999;
  @media (max-width: 600px) { // 600 is in the wp admin css file
    top: -46px
  }
}

.xdebug-error, .xe-warning {
  z-index: 999999999999999;
  position: relative;
}

html {
  scroll-behavior: smooth;
}

// anchors
a {
  @include mix.ease-it();

  &:hover {
    @include mix.ease-it();
  }
}

//fonts
h1, h2, h3, h4, h5, h6 {
  @include mix.font();
}

// images
.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail,
img,
figure {
  max-width: 100%;
  height: auto;
}

figure {
  overflow-x: auto !important;
}

.cc-content__article-inner img,
.cc-content__article-inner figure {
  margin-top: 15px;
  margin-bottom: 15px;
}

img.alignright, div.alignright,
figure.alignright, figure.alignright {
  float: unset !important;
  @include mix.media-above(var.$md) {
    float: right !important;
    margin: 10px 0 10px 25px;
  }
}

img.alignleft, div.alignleft,
figure.alignleft, figure.alignleft {
  float: unset !important;
  @include mix.media-above(var.$md) {
    float: left !important;
    margin: 10px 25px 10px 0;
  }

}

div.aligncenter {
  width: 100%;
}

figure.aligncenter, div.aligncenter figure,
figure.aligncenter, div.aligncenter figure {
  display: block;
  margin: 10px auto;
}

// a little tooltip treatment
.tooltip-inner {
  line-height: 1.4em;
}

strong, b {
  @include mix.font(700, true)
}

.clearfix {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}