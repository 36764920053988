@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

.tcc-sub-programs {
  margin-bottom: 50px;

  h2 {
    //text-transform: uppercase;
    margin-bottom: 20px;
  }

  h5 {
    margin-top: 30px;
  }

  .sub-rank {
    @include mix.font();
    font-size: 1.5em;
    text-align: center;
    vertical-align: middle;
    color: var.$primary2;
    width: 80px;
    background: adjust-color(var.$off-white, $lightness: -3%);
  }

  .nav {
    //border-bottom: 1px solid var.$sand;

    border-bottom: none;

    a, a:hover, a:focus, a:active, a:visited, a:focus-visible, a:focus-within {
      border: 1px solid var.$border-color;
      border-bottom: none;
      border-right: none;
      background: var.$off-white;
      @include mix.font();
      box-shadow: inset 0 -6px 4px -4px rgba(0, 0, 0, 0.1);
      color: adjust-color(var.$sand, $lightness: -3%);
      letter-spacing: 1px;

      &:last-child {
        border-right: 1px solid var.$border-color;
      }

      &:hover {
        color: var.$secondary;
      }
    }

    a.active {
      color: var.$secondary;
      background: white;
    }
  }

  table {
    background: var.$off-white;
    @include mix.boxshadow();

    a {

      box-shadow: none !important;
      padding: 0 !important;

      .primary-item {
        @include mix.font();
        color: var.$primary;
      }

      .secondary-item {
        font-size: .8em;
        color: var.$mud;

        span {
          @include mix.font();
          color: var.$gray-600;
        }
      }

      // make it concat if too long
      .programs {
        //overflow: hidden;
        //text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-box-orient: vertical;
        //-webkit-line-clamp: 1;
      }

      &:hover * {
        color: var.$secondary !important;
        @include mix.ease-it();
      }
    }

  }
}