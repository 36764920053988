@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

.source-list {
  margin: 50px 0;
  padding: 0;
  list-style: none;
  @include mix.boxshadow();
  border: 1px solid var.$sand;

  li {
    cursor: pointer;

    a {
      padding: 20px 20px !important;
      display: block;
      //border: 1px solid blue;
      box-shadow: none !important;
      background-color: adjust_color(var.$sand, $lightness: 12%)
    }

    &:nth-child(even) {
      a {
        background-color: adjust_color(var.$sand, $lightness: 15%)
      }
    }

    &:hover {
      a {
        background-color: adjust_color(var.$sand, $lightness: 18%)
      }

    }
  }
}