@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

[data-type="tcc_toc_anchor"] {
  display: block;
  height: 130px;
  width: 1px;
  margin-top: -130px;
}

.tcc_toc__list {
  border: 1px solid var.$sand;
  background-color: var.$off-white;
  @include mix.boxshadow();
  //display: inline-block;
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;

  li > a {
    display: flex;
    align-items: center;
    line-height: 1.2em;
    position: relative;
    box-shadow: none !important;
    padding: 15px !important;
    font-size: .8em;
    //text-align: center;
    &:hover {
      background-color: var.$white;
    }
  }
}

.tcc_toc__heading {
  @include mix.font();
  color: var.$primary;
  //text-transform: uppercase;
  letter-spacing: 1px;
  //text-align: center;
  line-height: 1.1em;
  white-space: pre-line;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .cc-svg {
    margin-right: 15px;
  }
}

.toc__list-number {
  @include mix.font();
  font-size: .8em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 0;
  width: 30px;
  height: 30px;
  color: white;
  flex-shrink: 0;
  letter-spacing: 1px;
  border-radius: var.$border-radius - 1;
  @include mix.color-gradient(var.$primary2);
}

@include mix.media-above(var.$md) {
  .tcc_toc__list {
    display: block;
    width: 350px;
    clear: both;
    float: right;
    margin: 0 0 20px 20px;

    li {

      &:first-of-type a {
        padding-top: 15px !important;
      }

      &:last-of-type a {
        padding-bottom: 15px !important;
      }

      a {
        padding: 5px 15px !important;
      }
    }

    &.align-left {
      float: left;
      margin: 0 20px 20px 0;
    }

    &.align-center {
      float: none;
      width: 80%;
      margin: 0 auto 20px auto;
    }
  }
}