@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

.stats {
  font-size: .8em;
  letter-spacing: .5px;
  line-height: 1.3em;
  border: 1px solid var.$sand;
  margin-bottom: 30px;
  border-radius: var.$border-radius;
  background: repeating-linear-gradient(
                  -55deg,
                  var.$off-white,
                  var.$off-white 150px,
                  adjust_color(var.$off-white, $lightness: -.5%) 150px,
                  adjust_color(var.$off-white, $lightness: -1.5%) 300px
  );
  @include mix.boxshadow();
}

.stats_heading {
  word-wrap: normal;
  text-align: center;
  padding: 15px 20px;
  margin: 40px 0 0 0;
  //text-transform: uppercase;
  font-size: 1.8em;
  letter-spacing: 1px;
  @include mix.font();
  color: var.$primary;

  .cc-svg {
    margin-right: 10px;
    top: -6px;
    position: relative;
  }
}

.stats__header,
.stats__footer {
  height: 40px;
  text-align: center;
  line-height: 40px;

  a {
    box-shadow: none !important;

    &:hover {
      color: var.$secondary !important;
      @include mix.ease-it();
    }
  }

  .cc-svg {
    margin: 0 10px;
    top: -2px;
    position: relative;
  }
}

$grad: adjust_color(var.$off-white, $lightness: -10%),
adjust_color(var.$off-white, $lightness: -5%) 10%,
adjust_color(var.$off-white, $lightness: -1%) 50%,
adjust_color(var.$off-white, $lightness: -5%) 60%,
adjust_color(var.$off-white, $lightness: -15%) 100%;

.stats__header {
  background: repeating-linear-gradient(55deg, $grad);
  border-bottom: 1px solid var.$sand;

}

.stats__footer {
  background: repeating-linear-gradient(-55deg, $grad);
  text-transform: none;
  border-top: 1px solid var.$sand;
}

// the whole table
.stats__inner {
}

// the IPEDS source byline under stats
.stats-source {
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
  margin-top: -20px;
  color: var.$gray-600;
  font-size: .6em;
  line-height: 1.2em;

  a {
    box-shadow: none !important;
  }
}
