@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

html, body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  flex: 1 0 auto;
}

footer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  @include mix.color-gradient(var.$primary2, -30%, -5%);
  color: var.$sand;

  // link colors
  a {
    color: var.$white;

    &:hover {
      color: var.$link-hover-color;
      @include mix.ease-it();
    }

    svg * {
      fill: var.$white;
    }

    &:hover svg * {
      fill: var.$link-hover-color;
      @include mix.ease-it();
    }

  }

  .footer__menu-container {
    padding: 20px;
    text-align: center;

    .footer__menu {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  .footer__meta {
    text-align: center;
    width: 100%;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    padding: 10px 0;
    margin: 0;

    svg {
      padding: 0 5px;
    }


    .footer__socials {
      display: inline-block;
    }

    .copyright {
      font-size: .7em;
      margin: 0;
      padding: 0;
    }

  }

}

@include mix.media-above(var.$lg) {

  body.qdf-bottom-active {
    margin-bottom: 0
  }
  footer {
    .footer__menu-container {
      display: flex;
      justify-content: center;
      column-gap: 40px;
      text-align: left;

      .footer__menu {
        width: max-content;
      }
    }
  }
}