@use "../cc/variables" as var;
@use "../cc/mixins" as mix;

.image-attribution-btn {
  color: var.$sand;
  @include mix.font();
  font-size: .6em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mix.ease-it();

  .cc-svg {
    padding: 0 8px 0 0;
  }

  &:hover {
    color: white;
  }

  &__hero {
    font-size: .8em;
  }

  &__ranks {
    z-index: 1;
    right: 10px;
    bottom: 50px;
    position: absolute;
    background: rgba(var.$black, .6);
    border-radius: var.$border-radius;
    padding: 5px 8px;
  }

  &__ranks {
    bottom: 10px;
  }

  &__card {
    //z-index: 1;
    //right: 10px;
    //top: 160px;
    //position: absolute;
    //height: 30px;
    //
    //&--sidebars {
    //  top: 60px;
    //
    //  .cc-svg {
    //    padding: 0
    //  }
    //
    //  span {
    //    display: none;
    //  }
    //}
  }

  &__rank-sample {
    z-index: 1;
    position: absolute;
    right: 15px;
    bottom: -25px;

    &:hover {
      color: var.$mud;
    }
  }

}


// the content of the tootltip
.tooltip__image-attribution {
  top: -50px !important;
}